import * as Sentry from '@sentry/browser';
import domready from 'domready';
import EventEmitter from 'eventemitter3';
import 'focus-visible';
import React, { LazyExoticComponent, Suspense } from 'react';
import { render } from 'react-dom';
import config from './utils/config';
import Sticky from './utils/sticky';

Sentry.init({
    dsn: config.RAVEN_DNS
});

// Eventemitter
const EE = new EventEmitter();
(window as any).__EE = EE;

interface Widget {
    type: string;
    component?: LazyExoticComponent<any>;
    fn?: any;
    lazy: boolean;
}

/**
 * Widgets to render
 */
const widgets: Widget[] = [
    {
        type: 'react--cookies',
        component: React.lazy(() => import('./components/cookies/cookieConsent')),
        lazy: false
    },
    {
        type: 'react--scrollto',
        fn: async () => import('./components/scrollto'),
        lazy: false
    },

    {
        type: 'react--scrollup',
        fn: async () => import('./components/scrollup'),
        lazy: false
    },
    {
        type: 'react--contact-sidebar',
        fn: async () => import('./components/contact'),
        lazy: false
    },
    {
        type: 'react--menu',
        fn: async () => import('./components/menu'),
        lazy: false
    },
    {
        type: 'react--hamburger',
        fn: async () => import('./components/hamburger'),
        lazy: false
    },
    {
        type: 'react--search',
        component: React.lazy(() => import('./components/search')),
        lazy: true
    },
    {
        type: 'react--documents',
        component: React.lazy(() => import('./components/documents')),
        lazy: true
    },
    {
        type: 'react--tabs',
        fn: async () => import('./components/tabs'),
        lazy: false
    },
    {
        type: 'react--map_germany',
        component: React.lazy(() => import('./components/maps/germany')),
        lazy: true
    },
    {
        type: 'react--map_world',
        component: React.lazy(() => import('./components/maps/world')),
        lazy: true
    },
    {
        type: 'react--process_certification',
        component: React.lazy(() => import('./plugins/process_certification')),
        lazy: true
    },
    {
        type: 'react--toolbox',
        fn: async () => import('./components/toolbox'),
        lazy: false
    },
    {
        type: 'react--cart_companies',
        fn: async () => import('./components/chart/companies'),
        lazy: false
    },
    {
        type: 'script--parallax',
        fn: async () => import('./plugins/parallax'),
        lazy: false
    },
    {
        type: 'react--youtube',
        component: React.lazy(() => import('./plugins/youtube')),
        lazy: false
    }
];

// helper functions
const each = (x: NodeListOf<Element>, f: (el: Element) => void) => Array.prototype.forEach.call(x, f);
const parse = (node: Element, x: string) => JSON.parse(node.getAttribute(x) || '{}');

/**
 * Renders a widget into an element
 * @param widget the widget to render
 * @param element the dom element
 */
const renderWidget = (widget: Widget, element: Element) => {
    try {
        const elementData = parse(element, 'data-state');
        const innerHtml = element.innerHTML;
        if (widget.component) {
            render(
                <Suspense fallback={<div />}>
                    <widget.component {...elementData} html={innerHtml} />
                </Suspense>,
                element
            );
        }
        if (widget.fn) {
            widget.fn().then((f: any) => f.default(element, elementData));
        }
    } catch (err) {
        console.error(`could not parse/load widget: ${widget.type}`, err);
        Sentry.captureException(err, {
            tags: {
                system: 'init'
            },
            extra: {
                widget: widget.type
            }
        });
    }
};

let hasInit = false;

/**
 * Main init function
 * @param lazy if the init is lazy
 * @returns
 */
const pefcInit = (lazy: boolean) => {
    console.log('pefcInit - lazy: ', lazy);

    // prevents re-init
    if (hasInit && lazy) {
        return;
    }

    hasInit = true;

    const widgetsToInit = widgets.map((w) => {
        w.lazy = lazy ? w.lazy : false;
        return w;
    });

    const lazyWidgets = widgetsToInit.filter((el) => el.lazy);
    const nonLazyWidgets = widgetsToInit.filter((el) => !el.lazy);
    if (lazyWidgets.length > 0) {
        const io = new IntersectionObserver((entries) => {
            const intersecting = entries.filter((e) => e.isIntersecting);
            intersecting.forEach((element) => {
                const elementType = element.target.getAttribute('data-type');
                const widget = lazyWidgets.find((el) => el.type === elementType);
                if (widget) {
                    renderWidget(widget, element.target);
                    io.unobserve(element.target);
                }
            });
        });

        lazyWidgets
            .filter((el) => el.lazy)
            .forEach((widget) => {
                const nodes = document.querySelectorAll('.' + widget.type);
                each(nodes, (node) => {
                    io.observe(node);
                });
            });
    }

    nonLazyWidgets.forEach((widget) => {
        const nodes = document.querySelectorAll('.' + widget.type);
        each(nodes, (node) => renderWidget(widget, node));
    });

    // only do sticky elements when we don not edit
    if ((window as any).CMS?.config?.mode !== 'draft') {
        // sticky elements
        new Sticky('.react--sticky');
    }
};

/**
 * Called on domready
 */
domready(() => {
    if (!(window as any).CMS && !(window as any).isDevelopment) {
        pefcInit(true);
    } else {
        try {
            pefcInit(false);
            window.setTimeout(() => {
                (window as any).CMS?.Plugin._refreshPlugins();
            }, 1000);

            (window as any).CMS?.$(window).on('cms-content-refresh', () => {
                pefcInit(false);
                window.setTimeout(() => {
                    (window as any).CMS?.Plugin._refreshPlugins();
                }, 1000);
            });
        } catch (err) {
            console.error(err);
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.on('vite:beforeUpdate', (data) => {
        if (data && data.type === 'update') {
            if (data.updates.find((el) => el.type === 'js-update')) {
                location.reload();
            }
        }
    });
}

import { ConfigurationOptions } from "typesense/lib/Typesense/Configuration";

interface Config {
    STATIC_URL: string;
    ENVIRONMENT: string;
    RAVEN_DNS: string;
    CURRENT_RELEASE: string;
    TYPESENSE_READ_ONLY_KEY: string;
    USER_IS_AUTHENTICATED: string;
}
const isProduction = process.env.NODE_ENV === 'production';

const config: Config = {
    ...(window as any).config,
    isProduction
};

export default config;


export const typesenseServer = {
    apiKey: isProduction ? config.TYPESENSE_READ_ONLY_KEY : 'local-typesense-api-key',
    nodes: [
        isProduction
            ? {
                  host: window.location.hostname,
                  port: window.location.port,
                  protocol: window.location.protocol.replace(':', '') as any,
                  path: '/search'
              }
            : {
                  host: 'localhost',
                  port: 8108,
                  protocol: 'http'
              }
    ]
} as ConfigurationOptions;
